
import UserWelcome from "Common/UserWelcome";
import {
  BrowserRouter,
    createBrowserRouter,
    HashRouter,
    Route,
    Routes,
    useLocation,
    useNavigate
  } from "react-router-dom";
import Dashboard from "Views/Dashboard/Dashboard";
import Test from "Views/Dashboard/Test";
import SignInPage from "Views/SignInPage/SignInPage";
import SignUpPage from "Views/SignUpPage/SignUpPage";
import ContactUs from "Views/ContactUs/ContactUs";
import StudentProfile from "Views/StudentProfile/StudentProfile";
import RecruiterProfile from "Views/RecruiterProfile/RecruiterProfile";
import RecruiterDashboard from "Views/RecruiterDashboard/RecruiterDashboard";
import StudentDashboard from "Views/StudentDashboard/StudentDashboard";
import Portal from "Views/Portal/Portal"
import './main.css'
import InviteUserList from "Views/InviteUsers/InviteUsers";
import RecruiterWelcome from "Views/RecruiterWelcome/RecruiterWelcome"
import JobDetails from "Views/Job/JobDetails";
import CreateJob from "Views/Job/CreateJob";
import CreateJobWrapper from "Views/Job/CreateJobWrapper";
import JobListWrapper from "Views/Job/JobLisintgWrapper";
import React from "react";


export const PageRoutes: React.FC = () => {

React.useEffect(()=>{
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }
},[])

  return (
    <BrowserRouter
                             
    >

<Routes>
<Route path="/dashboard" element={<Dashboard />} />
<Route path="/" element={<Dashboard />} />
<Route path="/signin"  element={<SignInPage />} />
<Route path="/signup" element={<SignUpPage />} />
<Route path="/contactus" element={<ContactUs />} />
<Route path="/welcome" element={<UserWelcome />} />
<Route path="/userProfile" element={<StudentProfile  />} />
<Route path="/recruiterProfile" element={<RecruiterProfile  />} />
<Route path="/portal" element={<Portal  />} />
<Route path="/inviteUsers" element={<InviteUserList  />} />
<Route path="/recruiter-welcome" element={<RecruiterWelcome  />} />
<Route path="/new-posting" element={<CreateJobWrapper  />} />
<Route path="/job-listings/:jobId" element={<JobListWrapper  />} />
<Route path="/job-listings" element={<JobListWrapper  />} />
<Route path="/recruiter-dashboard" element={<RecruiterDashboard  />} />
<Route path="/student-dashboard" element={<StudentDashboard  />} />
<Route path="/test" element={<Test  />} />


</Routes>
    </BrowserRouter>
  )
}
