

import React, { Children } from 'react';
export interface DashboardSideBarProps {
  recommenedCount:number

}
export const DashboardSideBar: React.FC<DashboardSideBarProps> = ({
  recommenedCount
}) => {

  const [userName,setUserName] = React.useState<string>('')
  React.useEffect(()=>{
    const userData = window.localStorage.getItem('user');
   
      if(userData){
        const parsedData = JSON.parse(userData);
      
        setUserName(parsedData?.user?.name)
        
      }
     },[])

  return (
<>
<div >
      <div className='dashSidebarWrapper'>
           <div className='welcome-text'>Welcome back <span className='ttC'>{userName}</span> ,<br/>
Below are the updates<br/>
since your last visit<br/><br/><br/>

<span className='ttC'>{recommenedCount} </span>New Candidate<br/>
Recommendation<br/><br/><br/>
</div>
        </div>
        
        </div>
</>
  );
}

export default DashboardSideBar;