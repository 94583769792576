import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { AuthError } from '@azure/msal-common';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import Sidebar from 'Common/Sidebar/Sidebar';
import Windows from '../../Assets/windows.png'
import TabView from 'Common/TabView/TabView';
import { validateEmail } from 'Utils/utils';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { Email } from '@mui/icons-material';
function SignInPage() {
  const nav = useNavigate()
  const location = useLocation()
const [studentEmail, setStudentEmail] = React.useState<
string
>('')
const [loading, setLoading] = React.useState<
boolean
>(false)
const [studentEmailError, setStudentEmailError] = React.useState<
boolean
>(false)
const [studentPassword, setStudentPassword] = React.useState<
string
>('')
const [studentPasswordError, setStudentPasswordError] = React.useState<
boolean
>(false)
const [jobId, setJobId] = React.useState<
string
>('')
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
 const loginRedirect=React.useCallback((error: AuthError | null, result?: any)=>{
console.log(error,result)
localStorage.setItem('user',JSON.stringify(result))
nav('/dashboard')
 },[])
 const handleChangeStudentInput = React.useCallback((type:string,value:string)=>{

   if(type==='Email'){
setStudentEmail(value)
if(studentEmailError){
 setStudentEmailError(false)
}
  }

  else{
   if(studentPasswordError){
     setStudentPasswordError(false)
   }
setStudentPassword(value)
  }
 },[studentPasswordError,studentEmailError])

const StudentSignIn = React.useCallback(()=> {
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/login`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json'},
    body: JSON.stringify({email:studentEmail,password:studentPassword})
  }) .then(response => {
    console.log(response)
    return response.json();
  }).then((data:any) => {
  
   if(data?.message==='User is not active'){
    nav('/signup',{ state: { email: studentEmail } })
    setLoading(false)
   }
  if(data.token ){
if(data?.user?.isActive){     
  window.localStorage.setItem('user',JSON.stringify({...data?.user,token:data.token}))
  setLoading(false)
  localStorage.removeItem('user')
  localStorage.setItem('user',JSON.stringify(data))
   if(jobId){
    nav('/job-listings/'+jobId)
   }

   else if(data?.user?.roleId===1){
    nav('/student-dashboard')
   }
   else {
    nav('/portal')
   }
  
  }
 
  }
  else{
    setSnackbarMessage(data?.message)
    setLoading(false)
  }
  }).catch((error)=>{
    console.log(error)
    setSnackbarMessage('Login Error')
    setLoading(false)
  })
},[studentEmail,studentPassword,jobId])
 const handleClickSignInButton = React.useCallback(()=>{
  let inputsValidated = true
   
   
    if(studentEmail?.trim()?.length===0 || !validateEmail(studentEmail)){
setStudentEmailError(true)
inputsValidated=false
    }
    if(studentPassword?.trim()?.length===0){
     setStudentPasswordError(true)
     inputsValidated=false
    } 
    if(inputsValidated){
      setLoading(true)
      StudentSignIn()
    }

 },[studentEmail,studentPassword])
 React.useEffect(()=>{
  if(snackbarMessage!==''){
    const messageTimeout = setTimeout(()=>{
      setSnackbarMessage('')
      clearTimeout(messageTimeout)
    },5000)
  }
 },[snackbarMessage])
    React.useEffect(()=>{
     if(location?.state?.jobId){
      setJobId(location?.state?.jobId)
     }

    },[location])
  return (
    <div className="loginWrapper">
      <div className='sideWrapper'><Sidebar /> </div>
      
      
     <div className='inputWrapper'>
     <Typography variant='h4' classes={{root:"getStartedText"}}>Sign In</Typography>
    
     <TextField required onKeyDown={(e)=>e.key==='Enter' && handleClickSignInButton()} error={studentEmailError} value={studentEmail} onChange={(e)=>handleChangeStudentInput('Email',e.target.value)} classes={{root:"inputField"}}   label="Email" type='text' />
     <TextField required onKeyDown={(e)=>e.key==='Enter' && handleClickSignInButton()} error={studentPasswordError} value={studentPassword} onChange={(e)=>handleChangeStudentInput('Password',e.target.value)} classes={{root:"inputField"}} label="Password" type='Password' />
   <Button className='signInButton'   onClick={()=>handleClickSignInButton()}>SIGN IN</Button>   
     
  </div>
  <div className='signInDiv'><div className='signInText'>Don't have an account? </div>  <Button onClick={()=>nav('/contactus')} className='signUpButton'>Contact Us</Button> </div>
  <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />
  <LoadingBackdrop isVisible={loading} />
    </div>
  );
}

export default SignInPage;