
import React, { FC } from 'react'
import './common.css'
import { Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Logo from '../Assets/LogoClr.svg'
import Next from 'Assets/arrowNext.png'
import WelcomeBG from '../Assets/welcomeBackground.svg'
import { useLocation, useNavigate } from 'react-router-dom';
const UserWelcome: FC<{ userName?: string }> = () => {
   const nav = useNavigate()
const location = useLocation()
const [userData,setUserName]= React.useState<any>()
   React.useEffect(()=>{
    if(location?.state?.user ){
 setUserName(location?.state?.user)
    }
   },[location])
   const navigateUser = React.useCallback(()=>{
    if(userData?.user?.roleId===4){
    nav('/inviteUsers')
    }

   else if(userData?.user?.roleId===1){
        nav('/userProfile')
    }
    else{
        nav('/recruiterProfile')

    }
   },[userData])

      React.useEffect(()=>{
        const user = localStorage.getItem('user')
        if(!user && (location?.pathname?.includes('contactus') 
          || location?.pathname?.includes('signup') 
        || location?.pathname?.includes('signin')
        || location?.pathname==='/'  
        || location?.pathname?.includes('dashboard')    
    )) {
          console.log('return')
        }
        else if(!user){
          nav('/signin')
        }
      },[location])

 
    return (
        <div className='welcomPageWrapper'>
            <a href=""><img className='welcomeLogo' src={Logo} alt="logo" /></a>
            <span className='welcomeHeading'>Hi, {userData?.user?.name}</span>
            <span className='welcomeSubHeading'>I am Sofia, your career assistant. Let’s get you started
</span>
<img src={Next} onClick={()=>navigateUser()} className='welcomeArrow' />
<a href=""><img className='welcomeBackground' src={WelcomeBG} alt="logo" /></a>
<span className='askMeText'>Ask me anything …   &nbsp;</span>
        </div>
    )
}

export default UserWelcome