

import React, { Children } from 'react';
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import './jobSidebar.css'
import Logo from  'Assets/company.avif'
import { baseURL } from 'Views/StudentProfile/StudentProfile';

import { daysBetween } from 'Utils/utils';
import SingleLine from 'Utils/SingleLine';
export interface JobSideBarProps {
    jobList:any
    activeJob:any
    onClickJobCard?:(job:any,index:number)=>void

}
export const JobSideBar: React.FC<JobSideBarProps> = ({
    jobList,
    activeJob,
    onClickJobCard
}) => {
const onClickJob = React.useCallback((item:any,index:number)=>{
    onClickJobCard?.(item,index)


},[])
React.useEffect(()=>{
const selectedJobDiv = document.getElementById(`activeJob-${activeJob?.JobDetails?.id}`)
if(selectedJobDiv){
    selectedJobDiv?.scrollIntoView()
    window.scrollTo(0, 0)
}

},[activeJob])
  return (
<>
<div >

        
      
        <div style={{width:410}} className='jobSidebarOuter'>
        {jobList?.map((item:any,index:number)=>{
          return (<div 
            id={`activeJob-${item?.JobDetails?.id}`}
            onClick={()=>onClickJobCard?.(item,index)}
          className={item?.JobDetails?.id===activeJob?.JobDetails?.id?'jobSidebarWrapperActive':'jobSidebarWrapper'}
          >
            <img src={!!item?.companyDetails?.companyLogo?baseURL+item?.companyDetails?.companyLogo: Logo} className='cardLogoSidebar'/>
             <div   className='jobcardDetailSidebar'>
         
          <div className='jobName'><SingleLine text={item?.JobDetails?.jobTitle} limit={35} /> </div>
          <div className='codeLine'><SingleLine text={item?.JobDetails?.company} limit={40} /> </div>
          <div className='profileName'><SingleLine text={item?.JobDetails?.jobFunction} limit={40} /></div>
          <div className='experiance'><SingleLine text={item?.JobDetails?.experience} limit={40} /></div>
          <div className='dateValueSidebar'>{daysBetween(item?.JobDetails?.createdAt)}</div>
        </div></div>)
        })}
        </div>
        
        </div>
</>
  );
}

export default JobSideBar;