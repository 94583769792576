import "./StudentDashboard.css"
import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import DashboardDetails from "./DashboardDetails";
import { GlobalSnackbar } from "Common/GlobalSnackbar";
import LoadingBackdrop from "Common/LoadingBackdrop";
function StudentDashboard() {
  const nav = useNavigate()
  const location = useLocation()
  const [activeTab,setActiveTab] = React.useState<string>('dashboard')
  const [loading,setLoading]= React.useState<boolean>(false)
  const [dashboardData,setDashboardData] = React.useState<any>()
  const [snackbarMessage, setSnackbarMessage] = React.useState<
  string
  >("")
  const handleToggleBtn=React.useCallback((value:string)=>{
  setActiveTab(value);
  },[])
  const getCandidateDashboard = React.useCallback((addCandidate?:boolean)=> {
    setLoading(true)
    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/candDashboard`, {
    method: 'GET',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
console.log(data)

setDashboardData(data?.data)
  setLoading(false)
  }).catch((error)=>{
    setSnackbarMessage(error?.message)
    setLoading(false)
  })
},[])
    React.useEffect(()=>{
     if(snackbarMessage){
       const close = setTimeout(()=>{
         setSnackbarMessage('')
      clearTimeout(close)
       },5000)
     }
   },[snackbarMessage])
   React.useEffect(()=>{
    getCandidateDashboard()
   },[])
          React.useEffect(()=>{
            const user = localStorage.getItem('user')
            if(!user && (location?.pathname?.includes('contactus') 
              || location?.pathname?.includes('signup') 
            || location?.pathname?.includes('signin')
            || location?.pathname==='/'  
            || location?.pathname?.includes('dashboard')    
        )) {
              console.log('return')
            }
            else if(!user){
              nav('/signin')
            }
          },[location])
  return (
    <div className="portal">
      <PortalSideBar activeTab={activeTab} changeTab={handleToggleBtn} /> 
   
      <div className="dashboardWrapper">
        <DashboardDetails dashboardData={dashboardData}  />
        <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />

<LoadingBackdrop isVisible={loading} />
        </div>
    </div>
  );
}

export default StudentDashboard;