
import React, { FC } from 'react'
import { Button, Dialog, MenuItem, Select, Slide, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './job.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface InviteUserProps {
    type:"Share" | "Invite" | "Close",
    jobData:any
    handleCloseDialog?:(type:'Share'|'Invite'|'Close',message?:string)=>void
    open?:boolean
}
const InviteCandidatePopup: FC<InviteUserProps> = ({type,jobData,handleCloseDialog,open}) => {
   const nav = useNavigate()
const location = useLocation()
const [userData,setUserName]= React.useState<any>()
const [loading,setLoading]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [inviteCandidateData,setInviteCandidateData]= React.useState<any>({jobId:jobData?.JobDetails?.id ,roleId:type==='Invite'?1:2,email:[''],isAccepted:false,shareDetails:true})

const [checked, setChecked] = React.useState(false);
const [checkDetails, setCheckDetails] = React.useState(true);
console.log(inviteCandidateData)
const handleChange=React.useCallback((event: React.ChangeEvent<HTMLInputElement>,type?:string)=>{
  if(type==='Details'){
    setCheckDetails(event.target.checked)
    setInviteCandidateData({...inviteCandidateData,shareDetails:event.target.checked})

  }
  else {
    setChecked(event.target.checked);
    setInviteCandidateData({...inviteCandidateData,isAccepted:event.target.checked})

  }
  
 
},[inviteCandidateData])
const handleChangeInput=React.useCallback((value:string,index:number)=>{
    const localInviteUserData = {...inviteCandidateData}
    setInviteCandidateData({...localInviteUserData,email:localInviteUserData?.email?.map((item:any,indexInner:number)=>{

        if(index===indexInner){
          return value
        }
        else return item
      })})
},[inviteCandidateData])
const addEmail = React.useCallback(()=>{
    let localData = {...inviteCandidateData}
    setInviteCandidateData({...localData,email:[...localData?.email,'']})
},[inviteCandidateData])
const deleteEmail = React.useCallback((index:number)=>{
    const localData = {...inviteCandidateData}

    const newJobData = [
      ...localData?.email?.slice(0,index),
      ...localData?.email?.slice(index+1)
    ]
    setInviteCandidateData({...localData,email:newJobData})
},[inviteCandidateData])
   React.useEffect(()=>{
    if(location?.state?.user ){
 setUserName(location?.state?.user)
    }
   },[location])

   const InviteCandidateAPI = React.useCallback(()=> {
    if(inviteCandidateData?.email?.length===0 || inviteCandidateData?.email?.[0]?.trim()?.length===0){
      setSnackbarMessage('Email is mendatory')
      setLoading(false)
      return
    }
      const userData = window.localStorage.getItem('user')
    const token = userData?JSON.parse(userData)?.token:''
    let param = inviteCandidateData;
 
    param.roleId= type === "Invite" ? 1 : 2;
    if(type === "Share"){
      param.isAccepted=checked;
    }
    else{
      param.isAccepted=true
    }
    let status = 0
    return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/inviteCandidate`, {
      method: 'POST',
      headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
      body: JSON.stringify(inviteCandidateData)
    }) .then(response => {
      status = response.status
      return response.json();
    }).then((data:any) => {
      setLoading(false)
        if(status===400){
          setSnackbarMessage(data?.message)
        }
        else{
          setInviteCandidateData({...inviteCandidateData,email:['']})
          handleCloseDialog?.('Close',type+ ' Process Completed')
        }
        
   
    }).catch((error)=>{
      console.log(error)
      setSnackbarMessage(error?.message)
      setLoading(false)
    })
  },[inviteCandidateData])



   const inviteCandidate=React.useCallback(()=>{
     const userData = window.localStorage.getItem('user')
    const email = userData?JSON.parse(userData)?.user?.email:''
    console.log(inviteCandidateData?.email,email)
    if(inviteCandidateData?.email?.length===0 ){
        setSnackbarMessage('Enter email')
    }
    else if(inviteCandidateData?.email?.some((item:string)=>item?.trim()?.length===0)){
      setSnackbarMessage('Enter email')
    }
    else if(inviteCandidateData?.email?.some((item:string)=>item===email)){
      setSnackbarMessage('Cannot use logged in email')
    }
    else {
        setLoading(true)
        InviteCandidateAPI()
    }
   },[inviteCandidateData])
   React.useEffect(()=>{
    if(snackbarMessage!==''){
      const messageTimeout = setTimeout(()=>{
        setSnackbarMessage('')
        clearTimeout(messageTimeout)
      },5000)
    }
   },[snackbarMessage])
    return (
        <Dialog
        open={open??false}
        fullWidth
        fullScreen
        classes={{container:'inviteDialog'}}
        >
    <Slide 
     direction="left"
                in={true}

                mountOnEnter
                unmountOnExit
                timeout={0}
                 ><div className='inviteUserContent'>
                    <div className='inviteHeadingContainer'><span className='inviteHeading'>{type==='Invite'?'Invite User':'Share Job'}</span>
                    <CloseIcon className='closeIconInvite' onClick={()=>handleCloseDialog?.('Close')} htmlColor='black' />
                    </div>
        
                 {inviteCandidateData?.email?.map((item:any,index:number)=>{
                    return  <div className='inviteCandidateContainer'>
                        <TextField placeholder='Enter Email' onChange={(event)=>handleChangeInput(event?.target?.value,index)} classes={{root:"inputFieldLinkedin"}}  value={item} type='text' />
            
                        {inviteCandidateData?.email?.length>1 &&<DeleteIcon  onClick={()=>deleteEmail(index)} fontSize='medium'  htmlColor='grey' className='deleteEmailInterview'  />}

                    </div>
                 })}
                            {/* <span onClick={addEmail}  className='addMoreLocations'>Add {type==='Invite'?'Candidate':'Recruiter'}</span> */}

<br/>
                        {type!=='Invite' &&  <FormControlLabel
        label="Share Candidate"
        control={
          <Checkbox
          checked={checked}
          onChange={(e)=>handleChange(e)}
          />
        }
      />}
      {/* {type!=='Invite' &&  <FormControlLabel
        label="Share Candidate Details"
        control={
          <Checkbox
          checked={checkDetails}
          disabled={!checked}
          onChange={(e)=>handleChange(e,'Details')}
          />
        }
      />}    */}

                  <Button onClick={()=>inviteCandidate()} className='inviteButtonCandidate'>{type}</Button>
                </div></Slide>
                <LoadingBackdrop isVisible={loading} />
                <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />

        </Dialog>
    )
}

export default InviteCandidatePopup