import "./RecruiterWelcome.css"
import React, { Children } from 'react';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import SearchBar from 'Common/SearchBar/SearchBar';
import CenterImg from '../../Assets/center_img.svg';
import AiImg from '../../Assets/aiBanner.svg';
import Close from '../../Assets/close.png';

function RecruiterWelcome() {
  const nav = useNavigate()
    const location = useLocation()
  
  const [userName,setUserName] = React.useState<string>('')
  React.useEffect(()=>{
    const userData = window.localStorage.getItem('user');
   
      if(userData){
        const parsedData = JSON.parse(userData);
      
        setUserName(parsedData?.user?.name)
        
      }
     },[])
        React.useEffect(()=>{
          const user = localStorage.getItem('user')
          if(!user && (location?.pathname?.includes('contactus') 
            || location?.pathname?.includes('signup') 
          || location?.pathname?.includes('signin')
          || location?.pathname==='/'  
          || location?.pathname?.includes('dashboard')    
      )) {
            console.log('return')
          }
          else if(!user){
            nav('/signin')
          }
        },[location])  
  return (
   
      <div className="main">
        <div className="welcomeBanner">
        <img src={AiImg} alt="" className="ai_img" />
            <div className="Welcome_text">Hello {userName}, Welcome to R2O</div>
            <div className="right_telent">Let’s find the right talent by posting few job openings</div>
            <div onClick={()=>nav('/new-posting')} className="post_new_opning_btn">Post New Opening</div>
            <div onClick={()=>nav('/job-listings')} className="add_from_job_portal">Job Listings</div>
            <img onClick={()=>nav('/userProfile')} src={Close} alt="" className="close_img" />
        </div>
    
      <div className="center_image" >
            <img src={CenterImg} className='' />
      </div>
      </div>
     
  );
}

export default RecruiterWelcome;