export const validateEmail = (value:string)=>{
    let validEmailPatter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmailPatter?.test(value)
}

export const daysBetween=(createdAt:any)=> {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
const firstDate:any = new Date();
const secondDate:any= new Date(createdAt);

const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays===0?'Today': (diffDays+' days ago')
}
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]
export const formattedDate=(date:any)=>{
    return monthNames[(new Date(date)).getMonth()]+' '+new Date(date)?.getFullYear()
}
export const returnTitleDate=(item?:any)=>{
  if(!!item){
    return new Date(item)?.getFullYear()
  }
  else return ''
}