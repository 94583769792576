
import React, { FC } from 'react'
import { Button, Dialog, MenuItem, Select, Slide, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './job.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { baseURL } from 'Views/StudentProfile/StudentProfile';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ProfileBoy from 'Assets/profileBoy.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface InviteUserProps {
   
    userData:any
    handleCloseDialog?:(value:boolean)=>void
    open?:boolean
}
const ViewProfile: FC<InviteUserProps> = ({userData,handleCloseDialog,open}) => {
   const nav = useNavigate()
const location = useLocation()
const [loading,setLoading]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")





   React.useEffect(()=>{
    if(snackbarMessage!==''){
      const messageTimeout = setTimeout(()=>{
        setSnackbarMessage('')
        clearTimeout(messageTimeout)
      },5000)
    }
   },[snackbarMessage])
    return (
        <Dialog
        open={open??false}
        fullWidth
        fullScreen
        >
    <Slide 
     direction="left"
                in={true}

                mountOnEnter
                unmountOnExit
                timeout={0}
                 ><div className='inviteUserContent'>
                    <div className='inviteHeadingContainer'><span className='inviteHeading'>Candidate Profile</span>
                    <CloseIcon className='closeIconInvite' onClick={()=>handleCloseDialog?.(false)} htmlColor='black' />
                    </div>
                    

                    <div className="card">
  <div className="card-top icon-text">
    <img src={userData?.userData?.profilePic ? baseURL + userData?.userData?.profilePic : ProfileBoy} alt="Profile Picture" className="profile-pic" />

    <div> 
      <div className='icon-text'><PersonIcon/> <span className='ml5'> {userData?.userData?.firstName ? userData?.userData?.firstName+' '+userData?.userData?.lastName: "---"}</span></div> 
      <div className='icon-text'><CallIcon/><span className='ml5'>  {userData?.userData?.phone ? userData?.userData?.phone :"---"}</span></div> 
      <div className='icon-text'><MailIcon/> <span className='ml5'>  {userData?.userData?.email ? userData?.userData?.email :"---"}</span></div> 
      <div className='icon-text'><LocationOnIcon/><span className='ml5'>  {userData?.userData?.region ? userData?.userData?.region :"---"}  {userData?.userData?.country ? ", " + userData?.userData?.country :""}  </span></div> 
    </div>
  </div>
  <div className="">  
  <div className="">



  <Accordion className='wp80'>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon  style={{
            color: "white"
          }} />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='headingClass'
        >
          <Typography  component="span"> Candidate Details :</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p><span className="key">Candidate Type:</span>  {userData?.userData?.carrierType ? userData?.userData?.carrierType :"---"}</p>
{!!userData?.userData?.userSkills.length && <>
        <h4 className='dIb key'>Skill : </h4>
        <ol className='dIb'>
      {userData?.userData?.userSkills.map((item: any, index: number)=>{
        let comma = userData?.userData?.userSkills.length === index + 1 ? "" : " ," ;
        return <li className='dIb'> {item?.skill?.name ? item?.skill?.name + comma  :"---"}</li>
      })}
      </ol>
      </>}
      <div></div>
{!!userData?.userData?.others?.certification.length && <>
        <h4 className='dIb key'>Certification : </h4>
        <ol className='dIb'>
      {userData?.userData?.others?.certification.map((item: any, index: number)=>{
        let comma = userData?.userData?.others?.certification.length === index + 1 ? "" : " ," ;
        return <li className='dIb'> {item ? item + comma  :"---"}</li>
      })}
      </ol>
      </>}
      <div></div>
      {!!userData?.userData?.others?.courses.length && <>
        <h4 className='dIb key'>Courses:</h4>
        <ol className='dIb'>
      {userData?.userData?.others?.courses.map((item: any, index: number)=>{
        let comma = userData?.userData?.others?.courses.length === index + 1 ? "" : " ," ;
        return <li className='dIb'> {item ? item + comma :"---"}</li>
      })}
      </ol>
      </>}
      <div></div>
      {!!userData?.userData?.others?.patents.length && <>
        <h4 className='dIb key'>Patents:</h4>
        <ol className='dIb'>
      {userData?.userData?.others?.patents.map((item: any, index: number)=>{
        let comma = userData?.userData?.others?.patents.length === index + 1 ? "" : " ," ;
        return <li className='dIb'> {item ? item + comma :"---"}</li>
      })}
      </ol>
      </>}
      <div></div>
      {!!userData?.userData?.others?.projectThesis.length && <>
        <h4 className='dIb key'>Project Thesis:</h4>
        <ol className='dIb'>
      {userData?.userData?.others?.projectThesis.map((item: any, index: number)=>{
        let comma = userData?.userData?.others?.projectThesis.length === index + 1 ? "" : " ," ;
        return <li className='dIb'> {item ? item + comma :"---"}</li>
      })}
      </ol>
      </>}
      { !!userData?.userData?.others?.publications.length && <>
        <h4 className='dIb key'>Publications:</h4>
        <ol className='dIb'>
      {userData?.userData?.others?.publications.map((item: any, index: number)=>{
        let comma = userData?.userData?.others?.publications.length === index + 1 ? "" : " ," ;
        return <li className='dIb'> {item ? item + comma :"---"}</li>
      })}
      </ol>
      </>}
          </Typography>
        </AccordionDetails>
      </Accordion>


   

    <Accordion className='wp80'>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon  style={{
            color: "white"
          }} />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='headingClass'
        >
          <Typography  component="span">  Work Preference  :</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ul>
        
        <li><span className="key">Preference Job Type:</span> {userData?.userData?.workPreference?.prefJobType ? userData?.userData?.workPreference?.prefJobType : "---"}</li>
        {/* <li><span className="key">Desired Salary:</span>  {userData?.userData?.workPreference?.desiredSal ? userData?.userData?.workPreference?.desiredSal + " $" : "---"}</li> */}
        <li><span className="key">Notice Period:</span> {userData?.userData?.workPreference?.NoticePeriod ? userData?.userData?.workPreference?.NoticePeriod + " Day" : "---"}</li>
        <li><span className="key">Preferd Job Type:</span> {userData?.userData?.workPreference?.prefJobType ? userData?.userData?.workPreference?.prefJobType : "---"}</li>
        <li><span className="key">Preferd Work Location:</span> {userData?.userData?.workPreference?.prefWorkLocation ? userData?.userData?.workPreference?.prefWorkLocation : "---"}</li>
      </ul> 
          </Typography>
        </AccordionDetails>
      </Accordion>


    <Accordion className='wp80'>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon  style={{
            color: "white"
          }} />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='headingClass'
        >
          <Typography  component="span"> Work Experience :</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          { !!userData?.userData?.workExperience.length ? 
userData?.userData?.workExperience.map((item: any, index: number)=>{
        return <ol className='wp50' >
          <li className='dIb key'>{index+1 + ". "}</li>
        <li className='dIb'><span className="key">Title:</span> {item?.title ? item?.title :"---"}</li>
        <li><span className="key">Company:</span> {item?.company ? item?.company :"---"}</li>
        <li><span className="key">Industry:</span> {item?.industry ? item?.industry :"---"}</li>
        <li><span className="key">Job Type:</span> {item?.jobType ? item?.jobType :"---"}</li>
        <li><span className="key">Location:</span> {item?.location ? item?.location :"---"}</li>
        <li><span className="key">Job Start Date:</span> {item?.startDate ? new Date(item?.startDate).toDateString() :"---"}</li>
        <li><span className="key">Job End Date:</span> {item?.endDate ? new Date(item?.endDate).toDateString() :"---"}</li>
        <li><span className="key">Description:</span> {item?.description ? item?.description :"---"}</li>
        
        <li className='mb10'><span className="key">Currently Working here:</span> {item?.currentlyWork ? "Yes" : "No"}</li>
      </ol> 
      } ) : <>No Record Found!!</> }
          </Typography>
        </AccordionDetails>
      </Accordion>

    
   

    <Accordion className='wp80'>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon  style={{
            color: "white"
          }} />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='headingClass'
        >
          <Typography  component="span"> Education :</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {!!userData?.userData?.education.length ? <>
      {userData?.userData?.education.map((item: any, index: number)=>{
        return <ol className='wp50'>
        <li className='dIb key'>{index+1 + ". "}</li>
        <li className='dIb'><span className="key">Area of Study:</span> {item?.areaStudy ? item?.areaStudy :"---"}</li>
        <li><span className="key">Degree:</span> {item?.degree ? item?.degree :"---"}</li>
        <li><span className="key">School:</span> {item?.school ? item?.school :"---"}</li>
        <li><span className="key">Start Date:</span> {item?.startDate ? new Date(item?.startDate).toDateString() :"---"}</li>
        <li><span className="key">End Date:</span> {item?.endDate ? new Date(item?.endDate).toDateString() :"---"}</li>
        <li className='mb10'><span className="key">Specialization:</span> {item?.specialization ? item?.specialization :"---"}</li>
      </ol> 
      })}
      </> : <>No Record Found!!</>}
          </Typography>
        </AccordionDetails>
      </Accordion>

      </div>
      
    <div className="">
      
     

     

      {userData?.userData?.coverLetter && <a target='_blank' href={baseURL+userData?.userData?.coverLetter} className="download-link" download>Download Cover Letter</a>}
      {userData?.userData?.resume && <a target='_blank' href={baseURL+userData?.userData?.resume} className="download-link" download>Download Resume</a>}
     
     
     
     
    </div>
    </div>
  </div>
                  
                </div></Slide>
                <LoadingBackdrop isVisible={loading} />
                <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />

        </Dialog>
    )
}

export default ViewProfile