import "../Portal/Portal.css"
import './job.css'
import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { AuthError } from '@azure/msal-common';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import SearchBar from 'Common/SearchBar/SearchBar';
import Windows from '../../Assets/windows.png'
import Jobtab from 'Common/Jobtab/Jobtab';
import InviteUsers from "Views/InviteUsers/InviteUsersPopup";
import InviteUserList from "Views/InviteUsers/InviteUsers";
import RecruiterWelcome from "../RecruiterWelcome/RecruiterWelcome";
import CreateJob from "Views/Job/CreateJob";
import LoadingBackdrop from "Common/LoadingBackdrop";
import { GlobalSnackbar } from "Common/GlobalSnackbar";
import { Jobcard } from "Common/Jobcard/Jobcard";
import JobDetails from "./JobDetails";
import JobSideBar from "./JobSidebar";
import CandidateTab from "Common/Jobtab/CandidateTab";
function JobListings() {
  const nav = useNavigate()
  const location  = useLocation()
  const [activeTab,setActiveTab] = React.useState<string>()
  const [jobList,setJobList] = React.useState<any>([])
  const [savedJobs,setSavedJobs] = React.useState<any>([])
  const [appliedJobs,setAppliedJobs] = React.useState<any>([])
  const [loading,setLoading]= React.useState<boolean>(false)
  const [userName,setUserName] = React.useState<string>('')
  const [selectedJobData,setselectedJobData] = React.useState<any>()
  const [showCreateJob,setShowCreateJob] = React.useState<boolean>(false)
  const [isSavedJob,setIsSavedJob] = React.useState<boolean>(false)
  const [isAppliedJob,setIsAppliedJob] = React.useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const userData= React.useMemo(()=>{
  const user = window.localStorage.getItem('user')
  if(user){
    return JSON.parse(user)
  }
  else return null
},[window])
 const handleToggleBtn=React.useCallback((value:string)=>{

setActiveTab(value);

  
  },[userData,jobList])
  const getJobLists = React.useCallback(()=> {
    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/getJobDetails`, {
    method: 'GET',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
  if(data?.jobs?.length!==0){
    if(userData && JSON.parse(userData)?.user?.roleId!==1){
      setJobList( data?.jobs.sort((a:any, b:any) =>
        {
          const first:any = new Date(b.JobDetails.createdAt)
          const second:any = new Date(a.JobDetails.createdAt)
          return first - second
        }
      ))
  
    }
    else{
      setJobList( data?.jobs?.sort((a:any, b:any) =>
        {
          const first:any = new Date(b.JobDetails.createdAt)
          const second:any = new Date(a.JobDetails.createdAt)
          return first - second
        }
      ))
    }
   

  }
 
  setLoading(false)
  }).catch((error)=>{
    console.log(error)
    setLoading(false)
  })
},[activeTab,userData])
const getAppliedSaved = React.useCallback((type:number)=> {
  const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
if(userData){
  if(JSON.parse(userData)?.user?.roleId!==1){
    return
  }
}
return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/getAppliedOrSavedJobs?type=${type}`, {
  method: 'GET',
  headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
}) .then(response => {
  return response.json();
}).then((data:any) => {
if(data?.jobs?.length!==0){
 
  if(type===0){
    setAppliedJobs( data?.jobs?.sort((a:any, b:any) =>
      {
        const first:any = new Date(b.JobDetails.createdAt)
        const second:any = new Date(a.JobDetails.createdAt)
        return first - second
      }
    ))
   }
   else if(type===1){
    setSavedJobs( data?.jobs.sort((a:any, b:any) =>
      {
        const first:any = new Date(b.JobDetails.createdAt)
        const second:any = new Date(a.JobDetails.createdAt)
        return first - second
      }
    ))
   }
  
 

}

setLoading(false)
}).catch((error)=>{
  console.log(error)
  setLoading(false)
})
},[activeTab,userData])
const onClickJobCard= React.useCallback((job:any,index:number)=>{
        
    setselectedJobData(job)
    setIsSavedJob(savedJobs?.filter((item:any)=>item?.JobDetails?.id===job?.JobDetails?.id)?.length!==0)
    setIsAppliedJob(appliedJobs?.filter((item:any)=>item?.JobDetails?.id===job?.JobDetails?.id)?.length!==0)
    nav('/job-listings/'+job?.JobDetails?.id)

},[savedJobs,appliedJobs])
const createJobAPI = React.useCallback(async(jobDataForMenu:any,isDraft?:boolean)=> {
   const localJob = {...jobDataForMenu}

    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/createJob`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify({...localJob,isDuplicate:true,JobDetails:{...localJob?.JobDetails,status:isDraft!==undefined?'Draft':localJob?.JobDetails?.status}})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
   getJobLists()
   getAppliedSaved(0)
   getAppliedSaved(1)
   if(isDraft){
    setSnackbarMessage('Posting updated Successfully')
   }
  }).catch((error)=>{
    console.log(error)
    setSnackbarMessage('Login Error')
    setLoading(false)
  })
},[])
const updateJob = React.useCallback(async(jobDataForMenu:any,status:string)=> {
  const localJob = {...jobDataForMenu}

   const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
 return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/updateJob/${localJob?.JobDetails?.id}`, {
   method: 'PUT',
   headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
   body: JSON.stringify({...localJob,JobDetails:{...localJob?.JobDetails,status:status}})
 }) .then(response => {
   return response.json();
 }).then((data:any) => {
  getJobLists()
  if(status){
   setSnackbarMessage('Posting updated Successfully')
  }
 }).catch((error)=>{
   console.log(error)
   setSnackbarMessage('Login Error')
   setLoading(false)
 })
},[])
const deleteJob = React.useCallback(async(jobDataForMenu:any)=> {
  const localJob = {...jobDataForMenu}

   const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
 return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/job/${localJob?.JobDetails?.id}`, {
   method: 'DELETE',
   headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
 }) .then(response => {
   return response.json();
 }).then((data:any) => {
  getJobLists()


 }).catch((error)=>{
   console.log(error)
   setSnackbarMessage('Login Error')
   setLoading(false)
 })
},[])
const onClickJobMenu= React.useCallback((action:string,event?:any,jobDataForMenu?:any)=>{
  event.stopPropagation()
  if(action==='Duplicate'){
    setLoading(true)

    createJobAPI(jobDataForMenu)
  }
  else if(action==='Edit'){
    nav('/new-posting',{ state: { jobToEdit: jobDataForMenu } })
  }
  else if(action==='SaveDraft'){
    setLoading(true)
    updateJob(jobDataForMenu,'Draft')
  }
  else if(action==='Delete'){
    setLoading(true)

    deleteJob(jobDataForMenu)
  }
  else if(action==='InActive'){
    setLoading(true)

    updateJob({...jobDataForMenu,JobDetails:{...jobDataForMenu?.JobDetails,status:'InActive'}},'InActive')
  }
  else if(action==='Active'){
    setLoading(true)

    updateJob({...jobDataForMenu,JobDetails:{...jobDataForMenu?.JobDetails,status:'Active'}},'Active')
  }

},[])
const handleChangeSaveApply=React.useCallback((type:string)=>{
  if(type==='saveJob'){
    setIsSavedJob(true)
  }
  else if(type==='applyJob'){
    setIsAppliedJob(true)
  }
},[])
const closeJobDetail = ()=>{
  setselectedJobData(undefined)
  nav('/job-listings')
  getJobLists()
  getAppliedSaved(1)
  getAppliedSaved(0)
}

React.useEffect(()=>{
  if(snackbarMessage){
    const close = setTimeout(()=>{
   setSnackbarMessage('')
   clearTimeout(close)
    },5000)
  }
},[snackbarMessage])
React.useEffect(()=>{
  const userData = window.localStorage.getItem('user');
  const jobId = location?.pathname?.split('/job-listings/')?.[1]
  if(!userData && !!jobId){
    nav('/signin',{ state: { jobId: jobId } })
  }
  if(location?.pathname?.includes('/job-listings/')){
    
     if(jobId && jobList){
      setselectedJobData(jobList?.filter((item:any)=>item?.JobDetails?.id===Number(jobId))?.[0])
     }
     if(!jobId){
      nav('/job-listings')
     }
  }
},[location,jobList])
 const renderJobs =React.useCallback(()=>{
    if(selectedJobData){
      const isStudent = userData?.user?.roleId===1
        return <div className="jobListingWrapper">
        <JobSideBar jobList={userData?.user?.roleId!==1?jobList?.filter(((item:any)=>(item?.JobDetails?.status===activeTab ||item?.JobDetails?.status==='onHold' && activeTab==='InActive' ))):(activeTab==='Saved'?savedJobs:(activeTab==='Applied'?appliedJobs:jobList))} activeJob={selectedJobData} onClickJobCard={onClickJobCard} />
        <JobDetails 
        isSaved={isSavedJob}
        isApplied={isAppliedJob}
        handleChangeSaveApply={handleChangeSaveApply}
        closeJobDetails={closeJobDetail} jobData={selectedJobData} /></div>
    }
    else {
        return <div >
        <div className='titleTextPortal'>
      <div className="topButtonContainer">    
            <div><div className='posting'>Posting</div>
      <div className='add_portal'>Add from Portal</div></div>
      {userData?.user?.roleId!==1 &&<Button className='signInButton' onClick={()=>nav('/new-posting')}>New Posting</Button>   }
      </div>
        </div>
        
        <div className="jobTab">
            {userData?.user?.roleId===1?
            <CandidateTab appliedCount={appliedJobs?.length} recommenedCount={jobList?.length} savedCount={savedJobs?.length} activeTab={activeTab??''} handleToggleBtn={handleToggleBtn} />:
            <Jobtab inactiveCount={jobList?.filter(((item:any)=>item?.JobDetails?.status==='InActive'||item?.JobDetails?.status==='onHold'))?.length} activeCount={jobList?.filter(((item:any)=>item?.JobDetails?.status==='Active'))?.length} draftCount={jobList?.filter(((item:any)=>item?.JobDetails?.status==='Draft'))?.length} activeTab={activeTab??''} handleToggleBtn={handleToggleBtn} />}
        </div>
        {jobList?.length===0 && <div className="noDataFound"><span>No Data Found</span></div>}
        <div>
          
        {(userData?.user?.roleId!==1?jobList?.filter(((item:any)=>(item?.JobDetails?.status===activeTab ||item?.JobDetails?.status==='onHold' && activeTab==='InActive' ))):(activeTab==='Saved'?savedJobs:(activeTab==='Applied'?appliedJobs:jobList)))?.map((item:any,index:number)=>{
          return (<Jobcard onMenuItemClick={onClickJobMenu} selectedTab={activeTab} jobData={item} onJobClick={onClickJobCard} jobIndex={index} />)
        })}
        </div>
        
        </div>
    }
   },[jobList,selectedJobData,activeTab,userData,savedJobs,appliedJobs,isSavedJob,isAppliedJob])
 const renderCreateJobs=()=>{
  return (<div >
    <div className='titleTextPortal'>
  <div>        <div className='posting'>Posting</div>
  <div className='add_portal'>Create New</div></div>
    </div>
 <CreateJob />
    
    </div>)      
 }

 React.useEffect(()=>{
  const userData = window.localStorage.getItem('user');
 
    if(userData){
        setLoading(true)
      const parsedData = JSON.parse(userData);
    
      setUserName(parsedData?.user?.name)
      
      getJobLists()
   
      if(activeTab) return
      if( parsedData?.user?.roleId===1){
        setActiveTab('Recommended')
        getAppliedSaved(0)
        getAppliedSaved(1)
      }
      else {
    
        setActiveTab('Active')

      }
    }
   },[userName,activeTab])
  return (
    <div className="jobListingContainer">
     {showCreateJob?renderCreateJobs():renderJobs()}
     <LoadingBackdrop isVisible={loading} />
<GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />
    </div>
  );
}

export default JobListings;