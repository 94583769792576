import React, { Children } from 'react';
import { MenuItem, MenuList, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import CreateJob from "Views/Job/CreateJob";
import LoadingBackdrop from "Common/LoadingBackdrop";
import { GlobalSnackbar } from "Common/GlobalSnackbar";
import { BarChart } from '@mui/x-charts/BarChart';

import { LineChart } from '@mui/x-charts/LineChart';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
export interface JobDetailProps {
  getRecommendedData:(value:number)=>void
  }
  export const DashboardDetails: React.FC<JobDetailProps> = ({
    getRecommendedData
  }) => {
  const userList:any = [{name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
    {name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
    {name:'John Doe',experience:'7 yrs',designation:'Cloud Architect',rating:4.2,peer:3.7,showMenu:false},
    
  ]
  const nav = useNavigate()
  const [loading,setLoading]= React.useState<boolean>(false)
  const [dashboardData,setDashboardData] = React.useState<any>()
  const [onHoldJobs,setOnHoldJobs] = React.useState<any>()
  const [activeJobs,setActiveJobs] = React.useState<any>()

  const [candidateMenuItem,setShowcandidateMenuItem] = React.useState<number>(-1)
  const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [activeTab,setActiveTab] = React.useState<string>('Summary')

const getRecruiterDashboard = React.useCallback((addCandidate?:boolean)=> {
  setLoading(true)
  const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/recommendedCandidate`, {
  method: 'GET',
  headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
}) .then(response => {
  return response.json();
}).then((data:any) => {
  getRecommendedData?.(data?.data?.length)
setDashboardData(data?.data)
setLoading(false)
}).catch((error)=>{
  setSnackbarMessage(error?.message)
  setLoading(false)
})
},[getRecommendedData])
const getActiveJobs = React.useCallback((addCandidate?:boolean)=> {
  setLoading(true)
  const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/getDayJob?status=Active`, {
  method: 'GET',
  headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
}) .then(response => {
  return response.json();
}).then((data:any) => {
setActiveJobs(data?.data)
setLoading(false)
}).catch((error)=>{
  setSnackbarMessage(error?.message)
  setLoading(false)
})
},[])
const getOnHoldJobs = React.useCallback((addCandidate?:boolean)=> {
  setLoading(true)
  const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/getDayJob?status=InActive`, {
  method: 'GET',
  headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
}) .then(response => {
  return response.json();
}).then((data:any) => {
setOnHoldJobs(data?.data)
setLoading(false)
}).catch((error)=>{
  setSnackbarMessage(error?.message)
  setLoading(false)
})
},[])
  React.useEffect(()=>{
   if(snackbarMessage){
     const close = setTimeout(()=>{
       setSnackbarMessage('')
    clearTimeout(close)
     },5000)
   }
 },[snackbarMessage])
 React.useEffect(()=>{
   getRecruiterDashboard()
   getActiveJobs()
   getOnHoldJobs()
 },[])

 
  


   const renderSummary=React.useCallback(()=>{
   
    const chartsParams = {
        margin: { bottom: 20, left: 25, right: 5 },
        height: 120,
        width:250
      };
    return <div><div className="chatContainerOuter">
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading">{activeJobs?.length??'-'}</span> <span className="chartTextValue">Active Posting</span>  </div>
        <BarChart
   xAxis={[
    { dataKey: "date",scaleType:'band' }
  ]}
  series={[
    { dataKey: "jobCount",color:'rgba(232,103,114,0.9)',valueFormatter:(value)=>Math.round(value!)?.toString() },
    
  ]}
  width={300}
  height={150}
  dataset={activeJobs}
  colors={['rgba(212,63,141,0.65)','rgba(2,80,197,0.25)']}
/>
        </div>
      
      
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading">{onHoldJobs?.length??'-'}</span> <span className="chartTextValue">Posting on Hold</span>  </div>
        <BarChart
   xAxis={[
    { dataKey: "date",scaleType:'band' }
  ]}
  series={[
    { dataKey: "jobCount",color:'rgba(232,103,114,0.9)',valueFormatter:(value)=>Math.round(value!)?.toString() },
    
  ]}
  width={300}
  height={150}
  dataset={onHoldJobs}
  colors={['rgba(212,63,141,0.65)','rgba(2,80,197,0.25)']}

/>
        </div>
        <div className="chatContainer">
         <div className="charTextWrapper"><span className="chartTextHeading">{dashboardData?.length??'-'}</span> <span className="chartTextValue">Candidates Recommended</span>  </div>
         <LineChart
        {...chartsParams}
      
        xAxis={[
          { dataKey: "date",scaleType:'band' }
        ]}
        series={[
          { dataKey: "candidateCount",color:'rgba(232,103,114,0.9)',valueFormatter:(value)=>Math.round(value!)?.toString() },
          
        ]}

        dataset={dashboardData}
      

      />
        </div>
    
    </div>
 
    </div>
   },[dashboardData,activeJobs,onHoldJobs])
  return (
    <div className="dashboardDetails">
    <div className="dashboard_txt">Dashboard</div>
    <div className='dashboardChart'> 
   {(dashboardData && activeJobs && onHoldJobs) && renderSummary()}
   </div>
 
 
     <LoadingBackdrop isVisible={loading} />
<GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />
    </div>
  );
}

export default DashboardDetails;