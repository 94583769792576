import "./Portal.css"
import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { AuthError } from '@azure/msal-common';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import SearchBar from 'Common/SearchBar/SearchBar';

import InviteUserList from "Views/InviteUsers/InviteUsers";
import RecruiterWelcome from "../RecruiterWelcome/RecruiterWelcome";
import JobListings from "Views/Job/JobListings";
function Portal() {
  const nav = useNavigate()
  const location = useLocation()
  const [activeTab,setActiveTab] = React.useState<string>('welcome')
  const [userName,setUserName] = React.useState<string>('')
  const [showCreateJob,setShowCreateJob] = React.useState<boolean>(false)
  
 const handleToggleBtn=React.useCallback((value:string)=>{

  setActiveTab(value);
  
  },[])

 const renderListings=()=>{
  return (<div className="main">
   <JobListings />
    
    </div>)      
 }
      React.useEffect(()=>{
        const user = localStorage.getItem('user')
        if(!user && (location?.pathname?.includes('contactus') 
          || location?.pathname?.includes('signup') 
        || location?.pathname?.includes('signin')
        || location?.pathname==='/'  
        || location?.pathname?.includes('dashboard')    
    )) {
          console.log('return')
        }
        else if(!user){
          nav('/signin')
        }
      },[location])
 React.useEffect(()=>{
  const userData = window.localStorage.getItem('user');
 
    if(userData){
      const parsedData = JSON.parse(userData);
    
      setUserName(parsedData?.user?.name)
      
    }
   },[userName])
 
  return (
    <div className="portal">
      <PortalSideBar activeTab={activeTab} changeTab={handleToggleBtn} /> 
      {/* <SearchBar />  */}
      
      {activeTab === "welcome" &&  <RecruiterWelcome  />}
     {(activeTab==='Amazone' || activeTab==='Linkedin' || activeTab==='Dice') && renderListings()}
  
     
    </div>
  );
}

export default Portal;