
import React, { FC } from 'react'
import { Button, Checkbox, Dialog, FormControlLabel, Slide, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './inviteUsers.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
interface InviteUserProps {
    handleCloseDialog?:(value:boolean,callInvite?:boolean,message?:string)=>void
    userToEdit?:any
    open?:boolean
}
const InviteUsersPopup: FC<InviteUserProps> = ({handleCloseDialog,userToEdit,open}) => {
   const nav = useNavigate()
const location = useLocation()
const [role, setRole] = React.useState('');
const [userData,setUserName]= React.useState<any>()
const [loading,setLoading]= React.useState<boolean>(false)
const [isAdmin,setIsAdmin]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [inviteUserData,setInviteUserData]= React.useState<any>({name:'',email:'',designation:'',isAdmin:false})
const isEdit = React.useMemo(()=>userToEdit?.designation!==undefined,[userToEdit])
const handleClickAdmin = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  setInviteUserData({...inviteUserData,isAdmin:event.target.checked});
},[inviteUserData]);
const handleChangeInput=React.useCallback((event:any,type:string)=>{
    const localInviteUserData = {...inviteUserData}
    setInviteUserData({...localInviteUserData,[type]:event?.target?.value})
},[inviteUserData])
   React.useEffect(()=>{
    if(userToEdit && isEdit){
      setInviteUserData({name:userToEdit?.name,email:userToEdit?.email,designation:userToEdit?.designation,isAdmin:userToEdit?.isAdmin})
  
    }
   },[userToEdit,isEdit])
   const InviteUsers = React.useCallback(()=> {
    setLoading(true)
      const userData = window.localStorage.getItem('user')
    const token = userData?JSON.parse(userData)?.token:''
    return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/${isEdit?'/recruiters/updateInviteUser':'/users/inviteUsers'}`, {
      method: isEdit?'PUT':'POST',
      headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
      body: JSON.stringify(inviteUserData)
    }) .then(response => {
      return response.json();
    }).then((data:any) => {
        handleCloseDialog?.(false,true,data?.message)
        setInviteUserData({name:'',email:'',designation:'',isAdmin:false})
   setLoading(false)
    }).catch((error)=>{
      console.log(error)
      handleCloseDialog?.(false,false,error?.message)
      setLoading(false)
    })
  },[inviteUserData,handleCloseDialog,isEdit])

   React.useEffect(()=>{
    if(snackbarMessage){
      const close = setTimeout(()=>{
        setSnackbarMessage('')
     clearTimeout(close)
      },5000)
    }
  },[snackbarMessage])

    return (
        <Dialog
        open={open??false}
        fullWidth
        fullScreen
        classes={{container:'inviteDialog'}}
        >
    <Slide 
     direction="left"
                in={true}

                mountOnEnter
                unmountOnExit
                timeout={0}
                 ><div className='inviteUserContent'>
                    <div className='inviteHeadingContainer'><span className='inviteHeading'>Invite User</span>
                    <CloseIcon className='closeIconInvite' onClick={()=>handleCloseDialog?.(false)} htmlColor='black' />
                    </div>
                 <span className='resumeLabel'>Name</span>
                  <TextField onChange={(event)=>handleChangeInput(event,'name')} classes={{root:"inputFieldLinkedin"}} value={inviteUserData?.name} type='text' />
                  <span className='resumeLabel'>Email</span>
                  <TextField disabled={(userToEdit?.designation!==undefined)} onChange={(event)=>handleChangeInput(event,'email')} classes={{root:"inputFieldLinkedin"}}  value={inviteUserData?.email} type='text' />
                  

                  <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                    <Select
                    classes={{root:"inputFieldLinkedin"}}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inviteUserData?.designation}
                      label="Age"
                      onChange={(event)=>handleChangeInput(event,'designation')}
                    >
                      {!isEdit && <MenuItem value={"Candidate"}>Candidate</MenuItem>}
                      <MenuItem value={"Recruiter"}>Recruiter</MenuItem>
                      <MenuItem value={"Hiring Manager"}>Hiring Manager</MenuItem>
                      <MenuItem value={"Platform Admin"}>Platform Admin</MenuItem>
                      <MenuItem value={"Business Development Manager"}>Business Development Manager</MenuItem>
                    </Select>
                    <div className='adminRights'>
                    {(inviteUserData?.designation==='Recruiter'
                      || inviteUserData?.designation==='Hiring Manager'
                      || inviteUserData?.designation==='Business Development Manager'
                    ) &&  <FormControlLabel
                            label="Admin Rights"
                            control={
                              <Checkbox
                              checked={inviteUserData?.isAdmin}
                              onChange={handleClickAdmin}
                              />
                            }
                          />} 
                    </div>
                  <Button onClick={()=>InviteUsers()} className='inviteButton'>{isEdit?'Save':'Invite'}</Button>
                </div></Slide>
                <LoadingBackdrop isVisible={loading} />
                <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />

        </Dialog>
    )
}

export default InviteUsersPopup