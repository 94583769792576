import "./RecruiterDashboard.css"
import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import DashboardDetails from "./DashboardDetails";
import DashboardSideBar from "./DashboardSideBar";
function RecruiterDashboard() {
 const nav = useNavigate()
   const location = useLocation()
   const [activeTab,setActiveTab] = React.useState<string>('dashboard')
   const [recommenedCount,setRecommenedCount]= React.useState<number>(0)
   const [snackbarMessage, setSnackbarMessage] = React.useState<
   string
   >("")
   const handleToggleBtn=React.useCallback((value:string)=>{
   setActiveTab(value);
   },[])

const getRecommendedData = (value:number)=>{
  setRecommenedCount(value)
}
          React.useEffect(()=>{
            const user = localStorage.getItem('user')
            if(!user && (location?.pathname?.includes('contactus') 
              || location?.pathname?.includes('signup') 
            || location?.pathname?.includes('signin')
            || location?.pathname==='/'  
            || location?.pathname?.includes('dashboard')    
        )) {
              console.log('return')
            }
            else if(!user){
              nav('/signin')
            }
          },[location])
  return (
    <div className="portal">
      <PortalSideBar activeTab={activeTab} changeTab={handleToggleBtn} /> 
   
      <div className="dashboardWrapper">
        <DashboardSideBar recommenedCount={recommenedCount} />
        <DashboardDetails getRecommendedData={getRecommendedData} />
        </div>
    </div>
  );
}

export default RecruiterDashboard;